import React from "react"

export const Footer = () => {
  return (
    // <footer className="footer mt-auto py-3 bg-light">
    <footer className="footer mt-auto py-3 bg-light">
      <div className="container">
        {/* <div className="row">
          <div className="col-12 footer-links">
            <a href="notevil.co" className="text-muted">
              Privacy Policy
            </a>
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-lg-4 mx-auto text-center text-muted copyright">
            <p>© 2021 Not Evil Ads by Not Evil Co</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
