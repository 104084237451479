import React, { useState } from "react"

// import { Button } from "./Button"

export const Navbar = () => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const menuClass = `collapse navbar-collapse ${isOpen ? "show" : ""}`

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-bg">
      <div className="container">
        <a className="logotype" href="/">
          <span className="logotype-ads">not evil</span> ads
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleOpen}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={menuClass} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href="/publishers">
                Publishers
              </a>
            </li>
            <li className="nav-item me-3">
              <a className="nav-link" href="/marketers">
                Marketers
              </a>
            </li> */}

            {/* <Button
              label="Sign in"
              className="btn btn-outline-primary me-3 my-1"
            ></Button> */}
            <a href="/#signup" className="btn btn-outline-primary me-3 my-1">
              Get notified
            </a>
            {/* <a href="/beta" className="btn btn-primary me-3 my-1">
              BETA
            </a> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}
